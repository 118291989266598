import { SpSvg } from '../autogen/SpSvg'
import { messageCardTypes } from '../config/messageCardTypes'
import { CardTypes } from '../constants/cardTypes'
import { IMessageCardType } from '../types/IMessageCardTypes'

export const useMessageCardColors = (
    cardType: undefined | CardTypes
): IMessageCardType => {
    if (cardType == undefined) {
        return {
            accentColor: '',
            icon: SpSvg.BasicErrorCircle,
            iconColor: '',
            mainColor: '',
        }
    }

    const cardConfig = computed(() => messageCardTypes[cardType])

    return { ...cardConfig.value }
}
